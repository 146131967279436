import { Button, DialogActions, TextField } from '@mui/material'
import React from 'react'
import { changePassword } from '../../../api/admin'
import { notify } from '../../Notify'

export default function ChangePassword({changeState}) {

    function handleOnSave() {
        let pwd = document.getElementById('change-password-txt')
        let cpwd = document.getElementById('change-cpassword-txt')
        let user = window.sessionStorage.getItem('user')

        if(!user) return notify('warning', "no user logged in")

        if(pwd.value !== cpwd.value) return notify('warning', "passwords don't match")

        changePassword({
            username: user,
            password: pwd.value
        }, (status) => {
            if(status === 'success') {
                notify('success', 'password changed')
                handleStateChange(false)
            }
        })
    }

    function handleStateChange(state) {
        changeState(state)    
    }

    return (
        <div id='change-password'>
            <TextField size='small' type='password' id="change-password-txt" placeholder='Password' />
            <TextField size='small' type='password' id="change-cpassword-txt" placeholder='Confirm Password' />

            <DialogActions>
                <Button onClick={() => handleOnSave()}>Save</Button>
                <Button color="secondary" onClick={() => handleStateChange(false)} >Cancel</Button>
            </DialogActions>
        </div>
    )
}
