import './styles/app.scss';
import Home from './pages/Home'
import NotificationContainer from './components/Notify';
import Login from './pages/Login';
import { useEffect, useState } from 'react';

function App() {
  const [LoggedIn, setLoggedIn] = useState(false)

  let loggingStatus = window.sessionStorage.getItem('loggedIn')

  useEffect(() => {
    setLoggedIn(loggingStatus === 'true')
  }, [loggingStatus])


  function handleOnLoginCallback() {
    let loggingStatus = window.sessionStorage.getItem('loggedIn')

    setLoggedIn(loggingStatus === 'true')
  }

  function handleLogout() {
    window.sessionStorage.setItem('loggedIn', 'false')
    window.sessionStorage.setItem('user', null)
    
    setLoggedIn(false)
  }

  return (
    <div className="App">
      {
        LoggedIn ?
          <Home logout={handleLogout} />
          :
          <Login callback={handleOnLoginCallback} />
      }

      <NotificationContainer />
    </div>
  );
}

export default App;
