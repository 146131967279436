import { Fade, IconButton, Menu, MenuItem } from '@mui/material';
import React, { Fragment, useState } from 'react'
import { MdMoreVert } from 'react-icons/md';

var handleClose;

export default function MoreOptions({id, children, ...props}) {

    const [anchorEl, setAnchorEl] = useState({});
    
    const handleClick = (event, id) => {
        setAnchorEl({[id]: event.currentTarget});
    };

    handleClose = (id) => {
        setAnchorEl({[id]: null});
    };

    return (
        <Fragment key={id} {...props}>
            <IconButton id={`${id}_menu`} onClick={(e) => handleClick(e, `${id}_menu`)} >
                <MdMoreVert/>
            </IconButton>
            <Menu
                id={`${id}_menu`}
                anchorEl={anchorEl[`${id}_menu`]}
                keepMounted
                open={Boolean(anchorEl[`${id}_menu`])}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {children}
            </Menu>
        </Fragment>
    )
}

export function OptionItem({id, children, onClick, deleteItem, ...props}) {

    function handleOnClick(id, e) {
        handleClose(id)
        onClick(e)
    }

    return (
        <MenuItem style={{color: deleteItem ? '#dc3545' : ''}} onClick={(e) => handleOnClick(`${id}_menu`, e)}>
            {children}
        </MenuItem>
    )
}
