import { Alert, Snackbar } from '@mui/material'
import React, { useState } from 'react'

//success
//error
//warning
//info

export var notify;

export default function NotificationContainer() {

    const [Open, setOpen] = useState(false)
    const [Data, setData] = useState({
        message: '',
        type: '' || 'error'
    })

    notify = (type, message, falseFormat) => {
        if(message && !falseFormat) message = capitalizeTxt(message)

        setData({type: type, message: message})
        setOpen(true)
    }

    function handleClose() {
        setOpen(false)
    }
    
    function capitalizeTxt(txt) {
        return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase(); //or if you want lowercase the rest txt.slice(1).toLowerCase();
    }

    return (
        <Snackbar 
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            open={Open} 
            autoHideDuration={2500} 
            onClose={handleClose}
        >     
            <Alert onClose={handleClose} variant="filled" elevation={6} severity={Data.type} sx={{ width: '100%' }}>
                {Data.message}
            </Alert>
        </Snackbar>
    )
}