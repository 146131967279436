import axios from 'axios';
import { notify } from '../components/Notify';
import config from '../config';

export async function userLogin(data, callback) {
    axios({
        method: 'POST',
        url: `${config.server}api/admin/login`,
        data: data
    })
        .then(res => {
            callback(res.data.status)
        })
        .catch(err => {
            callback(null)
            //console.log(err.response)
            if (err.response?.data) {
                if (err.response.data.message) {
                    notify('error', err.response.data.message)
                }
            } else {
                notify('error', 'Error')
            }
        })
}

export async function changePassword(data, callback) {
    axios({
        method: 'PATCH',
        url: `${config.server}api/admin/changePassword`,
        data: data
    })
        .then(res => {
            callback(res.data.status)
        })
        .catch(err => {
            callback(null)
            //console.log(err.response)
            if (err.response?.data) {
                if (err.response.data.message) {
                    notify('error', err.response.data.message)
                }
            } else {
                notify('error', 'Error')
            }
        })
}