require('dotenv').config()

const {
    REACT_APP_DEVELOPMENT,
    REACT_APP_SERVER,
} = process.env

module.exports = {
    development: REACT_APP_DEVELOPMENT ? REACT_APP_DEVELOPMENT === 'true' ? true : false : false ,
    server: REACT_APP_SERVER ? REACT_APP_SERVER.slice(-1) === '/' ? REACT_APP_SERVER : REACT_APP_SERVER + '/' : null,
}