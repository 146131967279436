import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import uuid from 'react-uuid'
import { deleteRecord, filterAll, findByIndex, getAll, getPagination } from '../api/users';
import { Button, TablePagination, TableSortLabel, TextField } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Box } from '@mui/system';
import PopUp from '../components/PopUp/PopUp';
import ChangePassword from '../components/PopUp/Containers/ChangePassword';
import MoreOptions, { OptionItem } from '../components/MoreOptions';
import ConfirmPopUp from '../components/ConfirmPopUp';
import UpdateRecord from '../components/PopUp/Containers/UpdateRecord';

const headCells = [
    {
        id: 'index',
        label: 'Index',
    },
    {
        id: 'extensionUID',
        label: 'Extension UID',
    },
    {
        id: 'timestamp',
        label: 'Timestamp',
    },
    {
        id: 'browserExtension',
        label: 'Browser Extension',
    },
    {
        id: 'trees',
        label: 'Trees',
    },
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'date',
        label: 'Date',
    },
    {
        id: 'uuid',
        label: 'UUID',
    },
    {
        id: 'friends',
        label: 'Friend counter',
    },
    {
        id: 'referralTrees',
        label: 'Referral Trees',
    },
]

export default function Home({ logout }) {
    const [Order, setOrder] = useState('asc');
    const [OrderBy, setOrderBy] = useState('index');
    const [Data, setData] = useState([])
    const [Pages, setPages] = useState(0)
    const [Page, setPage] = useState(0)
    const [Loading, setLoading] = useState(true)
    const [PageLoading, setPageLoading] = useState(true)
    const [ChangePasswordState, setChangePasswordState] = useState(false)
    const [HidePagination, setHidePagination] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [DeleteItem, setDeleteItem] = useState(null);
    const [EditItem, setEditItem] = useState({});

    useEffect(() => {
        let isMounted = true
        setPageLoading(true)

        getPagination(data => {
            setPageLoading(false)
            if (data?.count && isMounted) setPages(data.count)
        })

        return () => {
            isMounted = false
        }
    }, [])

    useEffect(() => {
        let isMounted = true

        if (isMounted) {
            setLoading(true)
            setHidePagination(false)

            fetchData({
                rows: rowsPerPage,
                endAt: false,
                page: 0,
                search: 0,
                column: OrderBy,
                order: Order
            })
        }

        return () => {
            isMounted = false
        }
    }, [])

    const handleOnPageChange = (event, value) => {
        let search = 0

        if (value > Page) {
            if (Data[Data.length - 1]) search = Data[Data.length - 1][OrderBy] ? Data[Data.length - 1][OrderBy] : 0
        } else {
            if (Data[0]) search = Data[0][OrderBy] ? Data[0][OrderBy] : 0
        }

        setLoading(true)
        setPage(value)

        fetchData({
            rows: rowsPerPage,
            endAt: value < Page,
            search: search,
            page: value,
            column: OrderBy,
            order: Order
        }, false)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = OrderBy === property && Order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        fetchFilterData({
            rows: rowsPerPage,
            column: property,
            order: isAsc ? 'desc' : 'asc'
        })
    };

    const handleChangePasswordState = (state) => {
        setChangePasswordState(state)
    }

    const handleOnSearch = (e) => {
        let val = e.target.value
        setLoading(true)

        if (!val) {
            fetchData({
                rows: rowsPerPage,
                endAt: false,
                search: 0,
                page: 0,
                column: 'index',
                order: 'asc'
            }, false)
        } else {
            setHidePagination(true)

            val = parseInt(val || '0')
            findByIndex(val, (data) => {
                setLoading(false)
                if (Array.isArray(data)) setData(data)
            })
        }
    }

    function fetchData(params, hidePages) {
        setHidePagination(!!hidePages)

        getAll(params, data => {
            setLoading(false)
            if (Array.isArray(data)) setData(data)
        })
    }

    function fetchFilterData(data) {
        setPage(0)

        filterAll(data, data => {
            setLoading(false)
            if (Array.isArray(data)) setData(data)
        })
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

        let search = 0

        if (Data[0]) search = Data[0][OrderBy] ? Data[0][OrderBy] : 0

        fetchData({
            rows: parseInt(event.target.value, 10),
            endAt: false,
            page: 0,
            search: search,
            column: OrderBy,
            order: Order
        }, false)
    };

    const handleOnEdit = (item, update) => {
        setEditItem(item)
        
        if(update?.id) {
            let index = Data.findIndex(i => i?.id == update?.id)

            if(index > -1) {
                Data[index] = update
                setData(Data)
            }
        }
    }

    const handleOnDeleteState = (id) => {
        setDeleteItem(id)
    }

    const handleOnDelete = () => {
        deleteRecord(DeleteItem, (status) => {
            if(status === 'success') {
                setData(Data.filter(i => i?.id != DeleteItem))
                handleOnDeleteState(null)
            }
        })
    }

    return (
        <div className='container' id='home'>

            <div className="top-wrap">
                <div className="wrap">
                    <h1 className="title">USERS V2</h1>
                    <TextField label="Search" placeholder='search by index' size='small' onChange={handleOnSearch} />
                </div>

                <div className="wrap">
                    <Button onClick={() => handleChangePasswordState(true)} variant='outlined' >Change Password</Button>
                    <Button onClick={logout} variant='outlined' color="secondary" >Logout</Button>
                </div>
            </div>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="users v2">
                    <EnhancedTableHead headCells={headCells} Order={Order} OrderBy={OrderBy} onRequestSort={handleRequestSort} />

                    <TableBody>
                        {
                            Loading ?
                                <TableRow
                                    key={uuid()}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>Loading...</TableCell>
                                </TableRow>
                                :
                                Data.length === 0 ?
                                    <TableRow
                                        key={uuid()}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>No records.</TableCell>
                                    </TableRow>
                                    :
                                    Data.map((item, index) => {
                                        return (
                                            <TableRow
                                                key={uuid()}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{`${parseInt(item.index || '0')}` || '-'}</TableCell>
                                                <TableCell>{item.extensionUID || '-'}</TableCell>
                                                <TableCell align="right">{item.timestamp || '-'}</TableCell>
                                                <TableCell align="right">{item.browser || '-'}</TableCell>
                                                <TableCell align="right">{item.trees ?? '-'}</TableCell>
                                                <TableCell align="right">{item.email || '-'}</TableCell>
                                                <TableCell align="right">{item.date || '-'}</TableCell>
                                                <TableCell align="right">{item.uuid || '-'}</TableCell>
                                                <TableCell align="right">{item.friends ?? '-'}</TableCell>
                                                <TableCell align="right">{item.referralTrees || '-'}</TableCell>
                                                <TableCell align="right">
                                                    <MoreOptions id={`home-more-options-${item.index}`}>
                                                        <OptionItem onClick={() => handleOnEdit(item)} >Edit</OptionItem>
                                                        <OptionItem deleteItem onClick={() => handleOnDeleteState(item.id)} >Delete</OptionItem>
                                                    </MoreOptions>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            {
                !HidePagination ?
                    PageLoading ?
                        <p className="loading">Loading pages ...</p>
                        :
                        <TablePagination
                            className='table-pagination'
                            component="div"
                            count={Pages}
                            page={Page}
                            onPageChange={handleOnPageChange}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    : null
            }

            <PopUp title="Change Password" state={ChangePasswordState} onClose={() => handleChangePasswordState(false)}>
                <ChangePassword changeState={(state) => handleChangePasswordState(state)} />
            </PopUp>

            <PopUp title="Update Record" state={Boolean(EditItem['id'])} onClose={() => handleOnEdit({})}>
                <UpdateRecord data={EditItem} changeState={(state, update) => handleOnEdit({}, update)} />
            </PopUp>

            <ConfirmPopUp title="Confirm" state={Boolean(DeleteItem)} onClose={() => handleOnDeleteState(null)} onConfirm={handleOnDelete}>
                Are you sure you want to delete this device ?
            </ConfirmPopUp>
        </div>
    )
}


function EnhancedTableHead({ headCells, Order, OrderBy, onRequestSort }) {

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {
                    headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={'right'}
                            sortDirection={OrderBy === headCell.id ? Order : false}
                        >
                            <TableSortLabel
                                active={OrderBy === headCell.id}
                                direction={OrderBy === headCell.id ? Order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {OrderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {Order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))
                }
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
    )

}