import { Button, DialogActions, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { update } from '../../../api/users'

export default function UpdateRecord({ data, changeState }) {
    const [SaveDisabled, setSaveDisabled] = useState(false)

    function handleOnSave() {
        let trees = document.getElementById('trees-count')
        setSaveDisabled(true)
        update({
            id: data.id,
            trees: trees.value ? parseInt(trees.value) : 1
        }, (res) => {
            setSaveDisabled(false)
            if (res.status === 'success') {
                handleStateChange(false, res.data)
            }
        })
    }

    function handleStateChange(state, update) {
        changeState(state, update)
    }

    useEffect(() => {
        if(data?.trees) {
            let trees = document.getElementById('trees-count')

            trees.value = parseInt(data.trees)
        }
    }, [data])
    

    return (
        <div id='update'>
            <TextField size='small' type='number' id="trees-count" placeholder='Trees' />

            <DialogActions>
                <Button disabled={SaveDisabled} onClick={() => handleOnSave()}>Save</Button>
                <Button color="secondary" onClick={() => handleStateChange(false)} >Cancel</Button>
            </DialogActions>
        </div>
    )
}
