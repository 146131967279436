import { Button, TextField } from '@mui/material'
import React from 'react'
import { userLogin } from '../api/admin'

export default function Login({callback}) {
    function handleOnLogin() {
        let username = document.getElementById('login-username')
        let password = document.getElementById('login-password')

        if(username.value && password.value) {
            
            userLogin({
                username: username.value,
                password: password.value
            }, (status) => {
                if(status === 'success') {
                    window.sessionStorage.setItem('loggedIn', 'true')
                    window.sessionStorage.setItem('user', username.value)
                } else {
                    window.sessionStorage.setItem('loggedIn', 'false')
                    window.sessionStorage.setItem('user', null)
                }

                callback()
            })
        }
    }

  return (
    <div className='container' id='login' >
        <h1 className="title">Login</h1>

        <TextField label="Username" size="small" className='input' id="login-username" placeholder='Username' />
        <TextField label="Password" size="small" className='input' id="login-password" placeholder='Password' type="password" />

        <Button onClick={handleOnLogin} variant='contained' >
            Login
        </Button>
    </div>
  )
}
