import axios from 'axios';
import { notify } from '../components/Notify';
import config from '../config';

export async function getAll(data, callback) {
    axios({
        method: 'POST',
        url: `${config.server}api/users/getAll`,
        data: data
    })
        .then(res => {
            callback(res.data.data)
        })
        .catch(err => {
            callback(null)
            //console.log(err.response)
            if (err.response?.data) {
                if (err.response.data.message) {
                    notify('error', err.response.data.message)
                }
            } else {
                notify('error', 'Error')
            }
        })
}

export async function filterAll(data, callback) {
    
    axios({
        method: 'POST',
        url: `${config.server}api/users/filterAll`,
        data: data
    })
        .then(res => {
            callback(res.data.data)
        })
        .catch(err => {
            callback(null)
            //console.log(err.response)
            if (err.response?.data) {
                if (err.response.data.message) {
                    notify('error', err.response.data.message)
                }
            } else {
                notify('error', 'Error')
            }
        })
}

export async function findByIndex(index, callback) {
    axios({
        method: 'GET',
        url: `${config.server}api/users/find/${index}`
    })
        .then(res => {
            callback(res.data.data)
        })
        .catch(err => {
            callback(null)
            //console.log(err.response)
            if (err.response?.data) {
                if (err.response.data.message) {
                    notify('error', err.response.data.message)
                }
            } else {
                notify('error', 'Error')
            }
        })
}

export async function getPagination(callback) {
    axios({
        method: 'GET',
        url: `${config.server}api/users/getPagination`
    })
        .then(res => {
            callback(res.data.data)
        })
        .catch(err => {
            callback(0)
            //console.log(err.response)
            if (err.response?.data) {
                if (err.response.data.message) {
                    notify('error', err.response.data.message)
                }
            } else {
                notify('error', 'Error')
            }
        })
}

export async function update(data, callback) {
    axios({
        method: 'PATCH',
        url: `${config.server}api/users/update/record`,
        data: data
    })
        .then(res => {
            notify(res.data.status, res.data.message)
            callback(res.data)
        })
        .catch(err => {
            callback(null)
            //console.log(err.response)
            if (err.response?.data) {
                if (err.response.data.message) {
                    notify('error', err.response.data.message)
                }
            } else {
                notify('error', 'Error')
            }
        })
}

export async function deleteRecord(id, callback) {
    axios({
        method: 'DELETE',
        url: `${config.server}api/users/delete`,
        data: {id: id}
    })
        .then(res => {
            callback(res.data.status)
        })
        .catch(err => {
            callback(null)
            //console.log(err.response)
            if (err.response?.data) {
                if (err.response.data.message) {
                    notify('error', err.response.data.message)
                }
            } else {
                notify('error', 'Error')
            }
        })
}